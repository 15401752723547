// PaymentPage.tsx
import React, { useState, useEffect } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { PaymentDone } from "api/stripe/stripe";
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";

import { StripeCardElementOptions } from "@stripe/stripe-js";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { useData } from "data/data-provider";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";

const cardElementOptions: StripeCardElementOptions = {
  style: {
    base: {
      color: "#32325d",
      fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
      fontSmoothing: "antialiased",
      fontSize: "16px",
      "::placeholder": {
        color: "#aab7c4",
      },
    },
    invalid: {
      color: "#fa755a",
      iconColor: "#fa755a",
    },
  },
};

const PaymentPage: React.FC = () => {
  const [clientSecret, setClientSecret] = useState<string | null>(null);
  const { saveCard } = useData();
  const stripe = useStripe();
  const elements = useElements();
  const navigate = useNavigate();
  const handleSubmit = async (event: React.FormEvent): Promise<void> => {
    event.preventDefault();

    if (!stripe || !elements) return;

    const cardNumberElement = elements.getElement(CardNumberElement);
    if (!cardNumberElement) {
      toast.error("Card element not found.");

      return;
    }

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: cardNumberElement,
    });
    if (error) {
      toast.error("Payment failed: " + error.message);

      return;
    } else {
      payDone(paymentMethod.id);
    }
  };

  const payDone = async (paymentMethodId: any) => {
    try {
      const data = await saveCard(paymentMethodId);
    } catch (error) {
      console.error("Error saving card", error);
    }
    try {
      const response = await PaymentDone(paymentMethodId, 930);
      if (response?.error) {
        toast.error(response?.error);
      } else {
        toast.success("Payment completed successfully!");
        navigate("/")
      }
    } catch (error: any) {
    } finally {
    }
  };

  return (
    <div className="flex items-start justify-center min-h-screen bg-gray-100">
      <form onSubmit={handleSubmit}>
        <div className="mt-8 pt-8 ">
          <span className="text-2xl  text-gray-700 dark:text-gray-300 font-semibold">
            Pending Amount
          </span>
          <br />
          <span className="text-lg  text-gray-700 dark:text-gray-300 font-medium">
            pauliusritter0@gmail.com
          </span>
          <br />
          <br />
          <span className="text-xl  text-gray-700 dark:text-gray-300 font-semibold">
            Invoice
          </span>
          <div className="flex  justify-between">
            <span className="text-lg  text-gray-700 dark:text-gray-300 font-medium">
              State Tax - 7%
            </span>
            <span className="text-lg  text-gray-700 dark:text-gray-300 font-medium">
              $42
            </span>
          </div>
          <div className="p-3 mt-4 border rounded-md">
            <CardNumberElement
              id="card-number-element"
              options={cardElementOptions}
              className="w-full"
            />
          </div>

          <div className="flex mt-4 space-x-4">
            <div className="flex-1 border p-3 rounded-md">
              <CardExpiryElement
                id="card-expiry-element"
                options={cardElementOptions}
                className="w-full"
              />
            </div>

            <div className="flex-1 border p-3 rounded-md">
              <CardCvcElement
                id="card-cvc-element"
                options={cardElementOptions}
                className="w-full"
              />
            </div>
          </div>
        </div>
        <ButtonPrimary className="w-full mt-4 rounded-lg" type="submit">
          Pay
        </ButtonPrimary>
      </form>
    </div>
  );
};

export default PaymentPage;
