import clientSay1 from "images/clientSay1.jpg";
import clientSay4 from "images/clientSay4.jpg";
import clientSay5 from "images/clientSay5.jpg";

export const globalJson = {
  prod_company_id: 12,
  dev_company_id: 12,

  // apiUtil auth header key
  authToken: "Bearer 171|oi0d3wgjpOBpuLKAqYnmU7XkzvlDTmAzmz1GzktC",
  companyId: "b8cb93b3-d95e-4fb8-ae13-146b4f64846f",
  stripePublishableKey:
    "pk_live_51PcuOnRra0lXsHPw9BuezMbtZC4UWDoKe79TEoh0o2RR47w1UKRBUEV4NTvWw6ulDk2junjATRHqTRea7wfOUSZT00h9ATeTiV",
  mapKey: "AIzaSyDKEntg7JydrxI7zr2tQL5OYcS5M-er-bc",
  residenceStateCode: "FL",

  adminEmailToken:
    "eSARL0qbFbAkFtLBzx2FajpsjqymAJPZkuo9Ojrb7WR5XLCt4VckcgVY2I5TK10szd1F8bd7MBkR4di0oui7Z1QGpzty9Mn4oTT2Fxfx4v42nydAb7y0rSkwtHE5ZWHt",
  websiteLink: "https://auto-motovation-ent-dev.1now.app/", //update from doc when provided
  sender: "auto-motovation",
  ccEmail: "automotivationent@gmail.com",

  // apiUtil sendNewsLetterEmail
  util_serviceId: "service_qtp0dg4",
  util_templateId: "template_zzaqcrs",
  util_userId: "5BABZb1-X9rZHDjmd",
  util_privateKey: "PLD5wzVCYqXSYCkfsMH-a",

  // apiUtil getBonzahToken
  client_secret_dev:
    "7Cy3Lh/ACZ1i5ngmljjuFI0jXdp4COOTzm8BwHENVHswnVOvraUtQHw0JaIVBi8tAnCCp/Nauiy3hUYdBLSUKjTaPjT52sn//L2XFSXiCnH+bghQfn9B0fwPx9OB8l648YEltQ==",
  client_id_dev:
    "16de63ef-82d9-45a5-9c61-45a497eac3e5-20240628473641-vendor@bonzah.com",
  client_secret_prod:
    "kW8STDVi7BqFVnsY1uTPkojt+h219LLyepclDXKoit9e8hjGTeMyMAqH3xr5snugYn4VRinxBR6PBpH6JkWCuLqkviR+jYaLwX23r7GPNS6BPMmeNCxl2xOCkv8kJzkhLGSg7g==",
  client_id_prod:
    "45a7eac5-dc32-47d6-8285-1601acd06a1e-20240628431701-vendor@bonzah.com",

  agreement_key:
    "https://firebasestorage.googleapis.com/v0/b/automotivation-59460.appspot.com/o/agreements%2F",
  bookingId_key: ".pdf?alt=media&token=8643371f-3ce3-4bd2-8108-11fbb1122f97",

  //   bg_config
  firebaseConfig: {
    apiKey: "AIzaSyD2vIYi5o6vwuR_vh8vXHVlE0wmPW18zxo",
    authDomain: "automotivation-59460.firebaseapp.com",
    projectId: "automotivation-59460",
    storageBucket: "automotivation-59460.appspot.com",
    messagingSenderId: "782768740562",
    appId: "1:782768740562:web:f064f5005287a8396bf50b",
    measurementId: "G-57J7LNFNXM",
  },

  about_us_para:
    "Here at Automotivation Enterprises, our mission is to provide seamless, convenient, and reliable car rental services that empower our customers to explore, connect, and experience the world on their own terms. We believe in creating extraordinary memories, fostering a sense of freedom, and making every journey unforgettable. Through our commitment to exceptional customer service, a diverse fleet of vehicles, and innovative technologies, we strive to be the go-to choice for all your car rental needs, enabling you to embrace every adventure with confidence. ",

  clientSays_arr: [
    {
      id: 1,
      clientName: "Melissa R.",
      clientAddress: "Dodge Grand Caravan 2019",
      content:
        "The Jeep was amazing! Loved driving it! And Josh is a great host. He was very responsive to all our questions (were Turo first timers). Highly recommend him and his Jeep!",
      img: clientSay1,
    },
    {
      id: 2,
      clientName: "Matthew P.",
      clientAddress: "LondDodge Grand Caravan 2019",
      content:
        "Josh was very responsive and flexible when my flight changed to a different airport at the last minute. Vehicle is well maintained as well.",
      img: clientSay4,
    },
    {
      id: 3,
      clientName: "Louis M.",
      clientAddress: "Dodge Grand Caravan 2019",
      content:
        "5/5 stars. Pick up/drop off was seamless. Outstanding communication and car was perfect. Will rent again",
      img: clientSay5,
    },
  ],

  //social links
  facebook: "https://www.facebook.com/", //pending
  instagram: "https://www.instagram.com/", //pending

  //contact
  address: "Deerfield Beach",
  addressLink:
    "https://www.google.com/maps/place/Deerfield+Beach,+FL,+USA/@26.3011291,-80.1223944,13z/data=!3m1!4b1!4m6!3m5!1s0x88d8e2903d4afb6f:0xe5982b2abf356617!8m2!3d26.3184123!4d-80.0997657!16zL20vMHJqMDY?entry=ttu",
  phone: "9549143862",
  email: "automotivationent@gmail.com",
};

export const scriptHubSpot = document.createElement("script");
scriptHubSpot.src = "//js-na1.hs-scripts.com/46614194.js";
scriptHubSpot.async = true;
scriptHubSpot.defer = true;
scriptHubSpot.id = "hs-script-loader";
