import { globalJson } from "global/global_json";


export const sendNewsLetterEmail = async (
    email: string,
    name: string,
    message: string
  ) => {
    const serviceId = globalJson?.util_serviceId;
    const templateId = globalJson?.util_templateId;
    const userId = globalJson?.util_userId;
    const privateKey = globalJson?.util_privateKey;
  
    await fetch("https://api.emailjs.com/api/v1.0/email/send", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Origin: "https://dashboard.emailjs.com",
      },
      body: JSON.stringify({
        service_id: serviceId,
        template_id: templateId,
        user_id: userId,
        accessToken: privateKey,
        template_params: { email, name, message },
      }),
    });
  };